import React from 'react';
import {
  LanguageSelector,
  LinkVariants,
  Navigation,
  NavigationItem,
  NavigationItemTrigger,
  NavigationList,
  Search,
  Header as SeldonHeader,
  SeldonProvider,
  UserManagement
} from '@phillips/seldon';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { camelCase } from 'lodash';
import PhillipsTranslations from '../PhillipsTranslations/PhillipsTranslations';
import { user as userPropTypes } from '../PropTypes/proptypes';
import { headerAuctionProps } from './proptypes';
import { useAuth } from '../Auth';
import sendAnalytics from '../../utils/sendAnalytics';
import searchMaker, { searchMakerClear } from '../ArtistSearch/actions';
import uriEncoder from '../../utils/uriencoder';

const AUCTIONS_MENU_LIST_SIZE = 10;

const AuctionsMenu = ({ headerAuctions, getI18nLabel, getI18nLink }) => (
  <NavigationItemTrigger
    id="auctions"
    label={getI18nLabel({ label: 'auctions' })}
  >
    <NavigationList
      leftSectionHeading={getI18nLabel({ label: 'upcoming' })}
      rightSectionHeading={getI18nLabel({
        label: 'auctionInformationAndServices'
      })}
    >
      {headerAuctions
        ?.slice(0, AUCTIONS_MENU_LIST_SIZE)
        ?.map(({ saleNumber, url, title, location, includeLocation }) => {
          const label = getI18nLabel({
            label: title,
            defaultValue: title
          });

          const badge =
            includeLocation && location
              ? getI18nLabel({
                  label: camelCase(location)
                })
              : null;

          return (
            <NavigationItem
              href={getI18nLink(url)}
              label={label}
              badge={badge}
              key={saleNumber}
              navGroup="nav-link-start"
              navType={LinkVariants.snwFlyoutLink}
            />
          );
        })}

      <NavigationItem
        href={getI18nLink('/calendar')}
        label={getI18nLabel({ label: 'viewAllAuctions' })}
        navGroup="nav-link-start"
        navType={LinkVariants.link}
        className="phillips-header-auction__all-link"
      />

      <NavigationItem
        href={getI18nLink('/auctions/past')}
        navGroup="nav-link-end"
        navType={LinkVariants.snwFlyoutLink}
        label={getI18nLabel({ label: 'auctionResults' })}
      />
      <NavigationItem
        href={getI18nLink('/artists')}
        navGroup="nav-link-end"
        navType={LinkVariants.snwFlyoutLink}
        label={getI18nLabel({ label: 'artistsAndMakers' })}
      />
      <NavigationItem
        href={getI18nLink('/buysell/buy?auctions')}
        navGroup="nav-link-end"
        navType={LinkVariants.snwFlyoutLink}
        label={getI18nLabel({ label: 'howToBuy' })}
      />
      <NavigationItem
        href={getI18nLink('/buysell/sell?auctions')}
        navGroup="nav-link-end"
        navType={LinkVariants.snwFlyoutLink}
        label={getI18nLabel({ label: 'howToSell' })}
      />
      <NavigationItem
        href={getI18nLink('/article/58561057/auction-bidding-anywhere')}
        navGroup="nav-link-end"
        navType={LinkVariants.snwFlyoutLink}
        label={getI18nLabel({ label: 'remoteBidding' })}
      />
      <NavigationItem
        href={getI18nLink('/private-sales-department')}
        navGroup="nav-link-end"
        navType={LinkVariants.snwFlyoutLink}
        label={getI18nLabel({ label: 'privateSales' })}
      />
      <NavigationItem
        href={getI18nLink('/professional-advisor-services')}
        navGroup="nav-link-end"
        navType={LinkVariants.snwFlyoutLink}
        label={getI18nLabel({ label: 'professionalAdvisorServices' })}
      />
      <NavigationItem
        href={getI18nLink('/fiduciaryservices')}
        navGroup="nav-link-end"
        navType={LinkVariants.snwFlyoutLink}
        label={getI18nLabel({ label: 'fiduciaryServices' })}
      />
    </NavigationList>
  </NavigationItemTrigger>
);

const Header = ({
  getI18nLabel,
  getI18nLink,
  handleLanguageChange,
  // eslint-disable-next-line react/prop-types
  handleLogin,
  language,
  // array with string like titles
  headerAuctions,
  // try to enable it and see what happens, should be handled by CMS
  notificationEnabled,
  // we need this flag for small modal
  hasSticky
}) => {
  const sendAnalyticsCall = (
    eventCategory,
    eventAction,
    eventLabel = 'www.phillips.com'
  ) => {
    sendAnalytics({
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel
    });
  };

  const sendAnalyticsCallClick = () =>
    sendAnalyticsCall('Dropshop click', 'Clicked Header Nav Link');

  const { authState } = useAuth();
  const dispatch = useDispatch();
  const store = useSelector(state => state);

  const searchResults =
    store?.searchOptions?.map(({ makerId, makerName }) => ({
      id: `${makerId}`,
      url: `/artist/${makerId}/${uriEncoder(makerName)}`,
      label: `${makerName}`
    })) || [];

  const onSearch = searchQuery =>
    searchQuery.length > 2
      ? dispatch(searchMaker(searchQuery))
      : dispatch(searchMakerClear());

  return (
    <SeldonProvider>
      <SeldonHeader
        className={classNames('phillips-header', {
          'phillips__nav__header--notification': notificationEnabled,
          'phillips__nav__header__has-sticky': hasSticky
        })}
        logoHref={getI18nLink('/')}
      >
        <Navigation>
          <NavigationList>
            <AuctionsMenu
              headerAuctions={headerAuctions}
              getI18nLabel={getI18nLabel}
              getI18nLink={getI18nLink}
            />

            <NavigationItem
              id="calendar"
              href={getI18nLink('/calendar')}
              label={getI18nLabel({ label: 'calendar' })}
            />

            <NavigationItemTrigger
              id="departments"
              label={getI18nLabel({ label: 'departments' })}
            >
              <NavigationList
                leftSectionHeading={getI18nLabel({
                  label: 'ourSpecialistDepartments'
                })}
              >
                <NavigationItem
                  href={getI18nLink('/modern-and-contemporary-art')}
                  navGroup="nav-link-start"
                  navType={LinkVariants.snwFlyoutLink}
                  label={getI18nLabel({ label: 'ModernAndContemporaryArt' })}
                />
                <NavigationItem
                  href={getI18nLink('/design')}
                  navGroup="nav-link-start"
                  navType={LinkVariants.snwFlyoutLink}
                  label={getI18nLabel({ label: 'design' })}
                />
                <NavigationItem
                  href={getI18nLink('/editions')}
                  navGroup="nav-link-start"
                  navType={LinkVariants.snwFlyoutLink}
                  label={getI18nLabel({ label: 'editions' })}
                />
                <NavigationItem
                  href={getI18nLink('/jewels')}
                  navGroup="nav-link-start"
                  navType={LinkVariants.snwFlyoutLink}
                  label={getI18nLabel({ label: 'jewels' })}
                />
                <NavigationItem
                  href={getI18nLink('/photographs')}
                  navGroup="nav-link-start"
                  navType={LinkVariants.snwFlyoutLink}
                  label={getI18nLabel({ label: 'photographs' })}
                />
                <NavigationItem
                  href={getI18nLink('/watches')}
                  navGroup="nav-link-start"
                  navType={LinkVariants.snwFlyoutLink}
                  label={getI18nLabel({ label: 'watches' })}
                />
                <NavigationItem
                  href={getI18nLink('/private-sales-department')}
                  navGroup="nav-link-start"
                  navType={LinkVariants.snwFlyoutLink}
                  label={getI18nLabel({ label: 'privateSales' })}
                />
              </NavigationList>
            </NavigationItemTrigger>

            <NavigationItem
              id="exhibitions"
              href={getI18nLink('/exhibitions')}
              label={getI18nLabel({ label: 'exhibitions' })}
            />

            <NavigationItem
              href={getI18nLink('/store/perpetual')}
              label={getI18nLabel({ label: 'perpetual' })}
            />

            <NavigationItem
              href="http://dropshop.phillips.com"
              label="Dropshop"
              onClick={sendAnalyticsCallClick}
            />

            <NavigationItem
              href={getI18nLink('/editorial')}
              label={getI18nLabel({ label: 'editorial' })}
            />
          </NavigationList>
          <Search
            placeholder={getI18nLabel({ label: 'search' })}
            onSearch={onSearch}
            searchResults={searchResults}
            onCancel={() => {
              dispatch({ type: 'SEARCH_MAKER_CLEAR' });
            }}
          />
        </Navigation>
        <LanguageSelector
          onLanguageChange={handleLanguageChange}
          currentLanguage={language}
        />
        <UserManagement
          authState={authState}
          onLogin={handleLogin}
          href={getI18nLink('/account')}
          loginLabel={getI18nLabel({ label: 'login' })}
          accountLabel={getI18nLabel({ label: 'account' })}
        />
      </SeldonHeader>
    </SeldonProvider>
  );
};

AuctionsMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headerAuctions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getI18nLabel: PropTypes.func.isRequired,
  getI18nLink: PropTypes.func.isRequired
};

Header.defaultProps = {
  hasSticky: false
};

Header.propTypes = {
  getI18nLabel: PropTypes.func.isRequired,
  getI18nLink: PropTypes.func.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
  notificationEnabled: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.shape(userPropTypes).isRequired,
  headerAuctions: headerAuctionProps.isRequired,
  hasSticky: PropTypes.bool
};

export default PhillipsTranslations(Header);
