import React, { Fragment } from 'react';
import camelCase from 'lodash/camelCase'
import PropTypes from 'prop-types';
import PhillipsTranslations from '../PhillipsTranslations/PhillipsTranslations';

const TIME_STATE = {
    0: "",
    1: "liveNow",
    2: "nextAuctions",
    3: "pastAuctions"
};

const TIME_STATE_SINGLE = {
    0: "",
    1: "liveNow",
    2: "nextAuction",
    3: "pastAuctions"
};

function StickyItem(props) {
    const { getI18nLabel, getTranslatedString } = props;

    const remainedTime = (new Date(props.StartDateTimeOffset)) - (new Date()); // in milliseconds 
    const titleI18n = getI18nLabel({ label: props.Title, defaultValue: props.Title });
    const sessionTitleI18n = props.Session ? props.Session.Title.replace(props.Title, titleI18n) : "";

    // const urlParts = props.URL.split("//");
    // const paths = urlParts[1] ? urlParts[1].split("/") : [];
    // const registerLink = `${urlParts[0]}//${paths[0] || ""}`;

    return (
      <div className="sticky-banner__item__inner-wrapper">
        <div className={`sticky-banner__item__main-info${props.isFirst ? "--first-child" : ""}`}>
          {props.duplicated ? null : (
            <div className="sticky-banner__item__time-state">
              {/* 0: unknown, 1: today, 2: upcoming, 3: past */}
              {props.hasSingle
                            ? getI18nLabel({ label: TIME_STATE_SINGLE[props.TimeState] })
                            : getI18nLabel({ label: TIME_STATE[props.TimeState] })
                        }
            </div>
                )}
          <div className="sticky-banner__item__title-meta">
            <div
              className="sticky-banner__item__title"
              title={`${getI18nLabel({ label: props.Title, defaultValue: props.Title })}${props.Session ? ` - ${props.Session.Title} ${props.Session.LotsRange}` : ''}`}
            >
              {titleI18n}
            </div>
            <div className={`sticky-banner__item__meta sticky-banner__item__meta${props.Session ? "--has-sessions" : ""}`}>
              <strong>
                {getI18nLabel({ label: camelCase(props.LocationName), defaultValue: props.LocationName })}
              </strong>
                        &nbsp;|&nbsp;
              {getTranslatedString('Date')}
                        &nbsp;|&nbsp;
              {getTranslatedString('Time')}
              {props.Session ? (
                <React.Fragment>
                  <br />
                  <strong>
                    {getI18nLabel({ label: sessionTitleI18n, defaultValue: sessionTitleI18n })}
                  </strong>
                                &nbsp;|&nbsp;
                  {getI18nLabel({ label: 'lots' })}
                                &nbsp;
                  {props.Session.LotsRange}
                </React.Fragment>
                        ) : null}
            </div>
          </div>
        </div>
        
        {props.SaleNumber === 'HK010424' && (props.TimeState === 1 || props.TimeState === 2)
  ? (
    <Fragment>
      <div className="sticky-banner__visit-link">
        <a
          href="https://live.phillips.com/live-auction/1-8SOW8P"
          className="sticky-banner__visit-link__button"
        >
          bid live
        </a>
      </div>
      <p style={{ marginTop: 28, marginBottom: 0, paddingTop: 20, borderTop: '1px solid #000' }}>
        Join us in the saleroom to watch the auction live. Please note, this option enters you into a hi-definition view-only experience
      </p>
      <div className="sticky-banner__visit-link">
        <a
          href="/livestream"
          className="sticky-banner__visit-link__button"
        >
          watch only
        </a>
      </div>
    </Fragment>
  )
  : 
  props.TimeState === 1 || props.TimeState === 2
      ? (
        <div className="sticky-banner__visit-link">
          <a
            href={props.URL}
            className="sticky-banner__visit-link__button"
          >
            {getI18nLabel({ label: remainedTime > 1200000 ? 'registerToBid' : 'watchBidLive' })}
          </a>
        </div>
      )
      : null
}
      </div>
    );
}

StickyItem.defaultProps = {
    Title: '',
    SaleNumber: '',
    LocationName: '',
    StartDateTimeOffset: '',
    Session: null,
    TimeState: 0,
    URL: '',
    duplicated: false,
    isFirst: false,
    hasSingle: false
};

StickyItem.propTypes = {
    Title: PropTypes.string,
    SaleNumber: PropTypes.string,
    LocationName: PropTypes.string,
    StartDateTimeOffset: PropTypes.string,
    Session: PropTypes.shape({
        Title: PropTypes.string,
        LotsRange: PropTypes.string,
        SessionTime: PropTypes.string,
        CSessionTime: PropTypes.string,
        SessionDate: PropTypes.string,
        CSessionDate: PropTypes.string
    }),
    TimeState: PropTypes.number,
    URL: PropTypes.string,
    getI18nLabel: PropTypes.func.isRequired,
    getTranslatedString: PropTypes.func.isRequired,
    duplicated: PropTypes.bool,
    isFirst: PropTypes.bool,
    hasSingle: PropTypes.bool
};

export default PhillipsTranslations(StickyItem);